export const ACCOUNT_TYPE = {
  CHECKING: 'CheckingAccount' as const,
  SAVINGS: 'SavingsAccount' as const
};

export const BILLING_FREQUENCY = {
  ANNUAL: 'annual' as const,
  MONTHLY: 'monthly' as const
};

export const LINKS = {
  ADD_BILLING_ADDRESS: 'add-billing-address' as const,
  ADD_SERVICE_ADDRESS: 'add-service-address' as const,
  APPLY_DISCOUNT: 'apply-discount' as const,
  EXPRESS_CHECKOUT: 'express-checkout' as const,
  GET_PLANS: 'get-plans' as const,
  MAINTENANCE_PAGE: 'maintenance' as const,
  PURCHASE: 'purchase' as const,
  RETRY_CURRENT_ADDRESS: 'retry-current-address' as const,
  RETRY_NEW_ADDRESS: 'retry-new-address' as const,
  RETRY_SUGGESTED_ADDRESS: 'retry-suggested-address' as const
};

export const PAGES = {
  CHECK_OUT: '/check-out',
  CONFIRMATION: '/confirmation',
  HOME: '/',
  MAINTENANCE: '/maintenance',
  SHOPPING: '/shopping'
};

export const PAYMENT_FIELDS = {
  CARD: 'card',
  CVV: 'cvv',
  EXPIRATION: 'exp',
  NAME: 'name'
};

export const PAYMENT_METHODS = {
  BANK_ACCOUNT: 'bank-account',
  CREDIT_CARD: 'credit-card'
};

export const PAYMENT_SELECTORS = {
  [PAYMENT_FIELDS.CARD]: 'data-cc-card',
  [PAYMENT_FIELDS.CVV]: 'data-cc-cvv',
  [PAYMENT_FIELDS.EXPIRATION]: 'data-cc-exp',
  [PAYMENT_FIELDS.NAME]: 'data-cc-name'
};

export const PAYMENT_CONFIG = {
  classes: { focus: 'focus-visibile-fields', invalid: 'invalid' },
  fields: {
    [PAYMENT_FIELDS.CARD]: { placeholder: '', selector: `[${PAYMENT_SELECTORS[PAYMENT_FIELDS.CARD]}]` },
    [PAYMENT_FIELDS.CVV]: { placeholder: '', selector: `[${PAYMENT_SELECTORS[PAYMENT_FIELDS.CVV]}]` },
    [PAYMENT_FIELDS.EXPIRATION]: { placeholder: 'MM/YY', selector: `[${PAYMENT_SELECTORS[PAYMENT_FIELDS.EXPIRATION]}]` },
    [PAYMENT_FIELDS.NAME]: { placeholder: '', selector: `[${PAYMENT_SELECTORS[PAYMENT_FIELDS.NAME]}]` }
  },
  styles: {
    input: {
      'background-color': 'transparent',
      color: 'black',
      'font-family': 'effra, sans-serif',
      'font-size': '16px'
    }
  }
};

export const STATIC_ASSETS = {
  PRIVACY_POLICY: '/NRGP_Policy_5.2022.pdf',
  TERMS_AND_CONDITIONS: '/NRGP_XOOM_CS_TC_10.22_Sample.pdf'
};

export const PAYMENT = {
  METHOD: 'method' as const
};

export const FILTERED_PLANS = {
  LINK_PLANS: 'link_plans',
  PLANS: 'plans' as const
};

export * from './states';
